import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import moment from 'moment';
import ptBR from 'date-fns/locale/pt-BR';

import { EstablishmentContext } from '../../../../context/EstablishmentContext';
import { getEstados } from '../../../../lib/getEstados';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import {
    optionsResultadoHPV,
} from '../../../../utils/variables';

import useHandleChangesFilters from './hooks/useHandleChangesFilters';
import useRequestFilter from './hooks/useRequestFilter';
import useVerifyAccessLevel from './hooks/useVerifyAccessLevel';
import useSetPayload from './hooks/useSetPayload';
import useClearValues, { ErrorField } from './hooks/useClearValues';

import MultipleFilters from '../../../../components/MultipleFilters';
import useStatesFields from '../../../../components/MultipleFilters/hooks/useStatesFields';
import useActions from '../../../../components/MultipleFilters/hooks/useActions';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';

const FiltersReportsConsolidado = ({
    setTableData,
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    errors,
    estadosSelected,
    setEstadosSelected,
    municipiosSelected,
    setMunicipiosSelected,
    estabelecimentosSelected,
    setEstabelecimentosSelected,
    setShowTableData,
    showTableData,
    setListFilter,
    listFilter,
    initialStateListFilter,
    listFilterShow,
    setListFilterShow,
    period,
    periodResultado,
    setPeriodResultado,
    setPeriod,
    disableInputEstado,
    setListFilterToShow,
    dtColetaIni,
    setDataColetaIni,
    dtColetaFinal,
    setDataColetaFinal,
    dtResultadoIni,
    setDataResultadoIni,
    dtResultadoFinal,
    setDataResultadoFinal,
    selectedLaboratorio,
    selectedOptinsProfissionalResponsavel,
    equipesSelected,
    setEquipesSelected,
    setDisableEquipeInput
}) => {
    const [disableOtherInputs, setDisableOtherInputs] = useState(true);

    const [estados, setEstados] = useState<any[]>([]);
    const [cidades, setCidades] = useState<any[]>([]);
    const [estabelecimentos, setEstabelecimentos] = useState<any[]>([]);

    const [showMotivoNaoAtendimento, setShowMotivoNaoAtendimento] =
        useState(false);

    const [errorInputDate, setErrorInputDate] = useState<any>();
    const [errorInputDateResultado, setErrorInputDateResultado] = useState<any>();

    const [disableFilters, setDisableFilters] = useState({
        estado: false,
        municipio: false,
        estabelecimento: false,
    });

    const { currentEstablishment } = useContext(EstablishmentContext);

    const location = useLocation();

    const {
        handleSetFilter,
        handleChangeRealizouAtendimento,
        getCidadesEstabelecimentos,
        getEstabelecimento,
    } = useHandleChangesFilters({
        setCidades,
        setEstabelecimentos,
        setValue,
        setListFilter,
        estados,
    });

    const {
        acsSelected,
        arrayAcs,
        searchAcs,
        allAcsOptions,
        anchorElAcs,
        disableAcsInput,

        setAnchorElAcs,
        setArrayAcs,
        setAcsSelected,
        setAllAcsOptions,
        setDisableAcsInput,

        anchorElEstados,
        searchEstado,
        estadosOptions,
        allEstadosOptions,

        setAnchorElEstados,
        setSearchEstado,
        setEstadosOptions,
        setAllEstadosOptions,

        allMunicipiosOptions,
        searchMunicipio,
        anchorElMunicipios,
        optionsMunicipios,
        disableMunicipioInput,

        setAllMunicipiosOptions,
        setSearchMunicipio,
        setAnchorElMunicipios,
        setOptionsMunicipios,
        setDisabledMunicipioInput,

        optionsEstabelecimentos,
        allEstabelecimentosOptions,
        searchEstabelecimentos,
        anchorElEstabelecimentos,
        disableEstabelecimentoInput,

        setOptionsEstabelecimentos,
        setAllEstabelecimentosOptions,
        setSearchEstabelecimentos,
        setAnchorEstabelecimentos,
        setDisabledEstabelecimentoInput,

        errorsFields,

        arrayEquipes,
        setArrayEquipes,

        setOptionsLaboratorios,
        setErrorsFields,
        setAllLaboratoriosOptions,
    } = useStatesFields();

    const { loadReportsConsolidado } = useRequestFilter({
        setTableData,
    });

    const { setPayload } = useSetPayload({
        watch,
        estadosSelected,
        municipiosSelected,
        estabelecimentosSelected,
        dtColetaFinal,
        dtColetaIni,
        dtResultadoIni,
        dtResultadoFinal,
        selectedLaboratorio,
        selectedOptinsProfissionalResponsavel,
    });

    const { permissao } = useVerifyAccessLevel({
        setValue,
        getCidadesEstabelecimentos,
        getEstabelecimento,
        currentEstablishment,
        setDisableFilters,
        handleSetFilter,
        setTableData,
        watch,
    });

    const { validationSubmit } = useActions();

    const { clearValues } = useClearValues({
        permissao,
        setValue,
        handleSetFilter,
        loadReportsConsolidado,
        watch,
        setEstadosSelected,
        setMunicipiosSelected,
        setEstabelecimentosSelected,
        showTableData,
        setTableData,
        setShowTableData,
        setErrorsFields: setErrorsFields as Dispatch<SetStateAction<ErrorField[]>>,
        setListFilterToShow,
        setDataColetaIni,
        setDataColetaFinal,
        setDataResultadoFinal,
        setDataResultadoIni,

    });

    const clearValuesInputs = () => { };

    const currentEstablishmentId = localStorage.getItem('id_last_establishment');

    const loadEstados = async () => {
        const response = await getEstados();
        setEstados(response);
    };

    const fnWhenFormIsValid = async () => {
        if (!errorInputDate && !errorInputDateResultado) {
            setTableData([]);
            setListFilterShow(listFilter);

            setShowTableData(true);

            await setPayload().then((payload) => {
                try {
                    loadReportsConsolidado(payload);
                } catch (error) { }
            });
        }
    };

    const onSubmit = async () => {
        validationSubmit({
            fnWhenFormIsValid,
            estadosSelected,
            municipiosSelected,
            estabelecimentosSelected,
            setErrorsFields,
            setDisableOtherInputs,
            watch,
            setError,
            clearErrors,
        });
    };

    const handleSetPeriod = (e) => {
        setPeriod(e.target.value);
        setDataColetaFinal(null);
        setDataColetaIni(null);

        setListFilterShow((prev) => {
            return prev.filter((field) => field.inputName !== 'dt_coleta_inicio');
        });
        setListFilterShow((prev) => {
            return prev.filter((field) => field.inputName !== 'dt_coleta_fim');
        });

        handleSetFilter('', 'dt_coleta_inicio');
        handleSetFilter('', 'dt_coleta_fim');
    };

    const handleSetPeriodResultado = (e) => {
        setPeriodResultado(e.target.value);
        setDataResultadoIni(null);
        setDataResultadoFinal(null);

        setListFilterShow((prev) => {
            return prev.filter((field) => field.inputName !== 'dt_resultado_inicio');
        });
        setListFilterShow((prev) => {
            return prev.filter((field) => field.inputName !== 'dt_resultado_fim');
        });

        handleSetFilter('', 'dt_resultado_inicio');
        handleSetFilter('', 'dt_resultado_fim');
    };

    useEffect(() => {
        setValue('estado', estadosSelected);
        setValue('municipio', municipiosSelected);
        setValue('estabelecimentoSaudeId', estabelecimentosSelected);
        setValue('estabelecimentoSaudeId', estabelecimentosSelected);
    }, [estadosSelected, municipiosSelected, estabelecimentosSelected]);

    useEffect(() => {
        loadEstados();
    }, []);


    const selectedChartIsResult = !!localStorage.getItem('result');
    const selectedChartIsTypeVirus = !!localStorage.getItem('typeVirus');
    const selectedChartIsReason = !!localStorage.getItem('reasonNotProcedure');
    const selectedChartOriginCollection =
        localStorage.getItem('collectionOrigin');

    const setListFilterDisplayByCharts = () => {
        if (selectedChartIsResult) {
            const value = localStorage.getItem('result');
            handleSetFilter(
                optionsResultadoHPV.find((item) => item.value === value)?.label,
                'resultado'
            );
        }
    };

    useEffect(() => {
        if (
            !!location.state ||
            selectedChartIsResult ||
            selectedChartIsTypeVirus ||
            selectedChartIsReason ||
            selectedChartOriginCollection
        ) {
            setListFilterDisplayByCharts();
        }
    }, []);

    const estabelecimentoInputValue = watch('estabelecimento_saude');

    useEffect(() => {
        if (!estabelecimentoInputValue) {
            setValue('motivo', null);
            setValue('idade_ini', null);
            setValue('idade_fin', null);
            setValue('realizou_atendimento', null);
            setValue('atendimento_status', null);
            setValue('inspecao_colo', null);
            setValue('responsavel_coleta', null);
            setValue('dt_coleta', null);
            setValue('resultado', null);
            setValue('estabelecimentoLaudoId', null);
            setValue('dt_resultado', null);
            setValue('search', null);

            initialStateListFilter.forEach((field) => {
                const isMunicipio = field.inputName === 'municipio';
                const isEstado = field.inputName === 'estado';
                if (isEstado || isMunicipio) {
                    return;
                } else {
                    handleSetFilter('', field.inputName);
                }
            });
        }
    }, [estabelecimentoInputValue]);

    useEffect(() => {
        const isAllEmpty = !!listFilter.find((item) => item.value !== '') === false;

        if (isAllEmpty) {
            setListFilterShow([]);
        }
    }, [listFilter]);

    useEffect(() => {
        const hasEstabelecimentoSelected = estabelecimentosSelected.length > 0;

        if (
            hasEstabelecimentoSelected
        ) {
            setDisableOtherInputs(false);
        }
    }, [estadosSelected, municipiosSelected, estabelecimentosSelected]);

    useEffect(() => {
        if (
            optionsMunicipios?.length > 0 &&
            municipiosSelected?.length === optionsMunicipios?.length
        ) {
            setDisableOtherInputs(false);
        } else if (municipiosSelected?.length === 0) {
            setDisableOtherInputs(true);
        }
    }, [optionsMunicipios, municipiosSelected]);

    const searchInput = watch('search');

    useEffect(() => {
        if (!searchInput) {
            clearErrors('search');
        }
    }, [searchInput]);

    const [errorInputDateColeta, setErrorInputDateColeta] = useState<any>({});

    useEffect(() => {
        const dtIni = moment(dtColetaIni, 'DD-MM-YYYY');
        const dtFin = moment(dtColetaFinal, 'DD-MM-YYYY');

        if (dtIni.isAfter(dtFin)) {
            setErrorInputDateColeta({
                ini: '',
                fin: 'Data final deve ser maior que a data inicial',
            });
        } else {
            setErrorInputDateColeta('');
        }
    }, [dtColetaFinal, dtColetaIni]);

    useEffect(() => {
        const dtIni = moment(dtResultadoIni, 'DD-MM-YYYY');
        const dtFin = moment(dtResultadoFinal, 'DD-MM-YYYY');

        if (dtIni.isAfter(dtFin)) {
            setErrorInputDateResultado({
                fin: 'Data final deve ser maior que a data inicial',
                ini: '',
            });
        } else {
            setErrorInputDateResultado('');
        }
    }, [dtResultadoIni, dtResultadoFinal]);

    return (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DisplayFilter style={{ marginTop: '24px' }}>
                        {!listFilterShow?.find((item) => item.value !== '') && (
                            <p style={{ margin: 0, width: '413px', color: '#838383' }}>
                                Os filtros selecionados aparecerão aqui.
                            </p>
                        )}

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '100%',
                                gap: '10px',
                                flexWrap: 'wrap',
                            }}
                        >
                            {listFilterShow.map((item) => (
                                <>
                                    {item.value ? (
                                        <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                                    ) : undefined}
                                </>
                            ))}
                        </div>
                    </DisplayFilter>
                </Grid>

                <MultipleFilters
                    clearValuesInputs={clearValuesInputs}
                    setDisableOtherInputs={setDisableOtherInputs}
                    setAllLaboratoriosOptions={setAllLaboratoriosOptions}
                    setOptionsLaboratorios={setOptionsLaboratorios}
                    disableInputEstado={disableInputEstado}
                    handleSetFilter={handleSetFilter}
                    mdEstado={4}
                    mdMunicipio={4}
                    mdEstabelecimento={4}
                    acsSelected={acsSelected}
                    arrayAcs={arrayAcs}
                    searchAcs={searchAcs}
                    allAcsOptions={allAcsOptions}
                    setAllAcsOptions={setAllAcsOptions}
                    anchorElAcs={anchorElAcs}
                    disableAcsInput={disableAcsInput}
                    setAnchorElAcs={setAnchorElAcs}
                    setArrayAcs={setArrayAcs}
                    setSearchAcs={setArrayAcs}
                    setAcsSelected={setAcsSelected}
                    anchorElEstados={anchorElEstados}
                    searchEstado={searchEstado}
                    estadosOptions={estadosOptions}
                    allEstadosOptions={allEstadosOptions}
                    estadosSelected={estadosSelected}
                    setAnchorElEstados={setAnchorElEstados}
                    setSearchEstado={setSearchEstado}
                    setEstadosOptions={setEstadosOptions}
                    setAllEstadosOptions={setAllEstadosOptions}
                    setEstadosSelected={setEstadosSelected}
                    allMunicipiosOptions={allMunicipiosOptions}
                    equipesSelected={equipesSelected}
                    setEquipesSelected={setEquipesSelected}
                    setDisableEquipeInput={setDisableEquipeInput}
                    searchMunicipio={searchMunicipio}
                    anchorElMunicipios={anchorElMunicipios}
                    municipiosSelected={municipiosSelected}
                    optionsMunicipios={optionsMunicipios}
                    disableMunicipioInput={disableMunicipioInput}
                    setAllMunicipiosOptions={setAllMunicipiosOptions}
                    setSearchMunicipio={setSearchMunicipio}
                    setAnchorElMunicipios={setAnchorElMunicipios}
                    setMunicipiosSelected={setMunicipiosSelected}
                    setOptionsMunicipios={setOptionsMunicipios}
                    optionsEstabelecimentos={optionsEstabelecimentos}
                    allEstabelecimentosOptions={allEstabelecimentosOptions}
                    searchEstabelecimentos={searchEstabelecimentos}
                    anchorElEstabelecimentos={anchorElEstabelecimentos}
                    estabelecimentosSelected={estabelecimentosSelected}
                    disableEstabelecimentoInput={disableEstabelecimentoInput}
                    setOptionsEstabelecimentos={setOptionsEstabelecimentos}
                    setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
                    setSearchEstabelecimentos={setSearchEstabelecimentos}
                    setAnchorEstabelecimentos={setAnchorEstabelecimentos}
                    setEstabelecimentosSelected={setEstabelecimentosSelected}
                    setDisabledMunicipioInput={setDisabledMunicipioInput}
                    setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
                    setDisableAcsInput={setDisableAcsInput}
                    errorsFields={errorsFields}
                    setErrorsFields={setErrorsFields}
                    arrayEquipes={arrayEquipes}
                    setArrayEquipes={setArrayEquipes}
                />

                <Grid item xs={12} md={4}>
                    <Controller
                        name="atendimento_teste_hpv"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    // disabled={disableOtherInputs}
                                    options={[
                                        'Realizado',
                                        'Não Realizou',
                                    ]}
                                    onChange={(_, newValue) => {
                                        handleChangeRealizouAtendimento(
                                            newValue,
                                            setShowMotivoNaoAtendimento,
                                            onChange
                                        );
                                    }}
                                    value={value ? value : ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            data-testid="inputRealizouAtendimento"
                                            size="small"
                                            label="Atendimento do Teste de HPV"
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Controller
                        name="resultado_teste_hpv"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    // disabled={disableOtherInputs}
                                    options={['Inconclusivo', 'Não Detectável', 'Detectável']}
                                    onChange={(_, newValue) => {
                                        handleChangeRealizouAtendimento(
                                            newValue,
                                            setShowMotivoNaoAtendimento,
                                            onChange
                                        );
                                    }}
                                    value={value ? value : ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            data-testid="inputRealizouAtendimento"
                                            size="small"
                                            label="Resultado do Teste de HPV"
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Controller
                        name="dt_resultado_citologia"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    // disabled={disableOtherInputs}
                                    options={['Com Alterações Epiteliais', 'Insatisfatório', 'Negativo']}
                                    onChange={(_, newValue) => {
                                        handleChangeRealizouAtendimento(
                                            newValue,
                                            setShowMotivoNaoAtendimento,
                                            onChange
                                        );
                                    }}
                                    value={value ? value : ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            data-testid="inputRealizouAtendimento"
                                            size="small"
                                            label="Resultado da Citologia"
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Controller
                        name="tipos_achados"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    // disabled={disableOtherInputs}
                                    options={[
                                        'Achado Colposcópico Normal',
                                        'Achado Colposcópico Anormal'
                                    ]}
                                    onChange={(_, newValue) => {
                                        handleChangeRealizouAtendimento(
                                            newValue,
                                            setShowMotivoNaoAtendimento,
                                            onChange
                                        );
                                    }}
                                    value={value ? value : ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            data-testid="tiposAchados"
                                            size="small"
                                            label="Tipos de Achados"
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Controller
                        name="tipos_achados"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    // disabled={disableOtherInputs}
                                    options={['Sim', 'Não']}
                                    onChange={(_, newValue) => {
                                        handleChangeRealizouAtendimento(
                                            newValue,
                                            setShowMotivoNaoAtendimento,
                                            onChange
                                        );
                                    }}
                                    value={value ? value : ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            data-testid="realizarBiopsia"
                                            size="small"
                                            label="Realizar Biópsia"
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Controller
                        name="tipos_achados"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    // disabled={disableOtherInputs}
                                    options={['Biópsia', 'Peça Cirúrgica']}
                                    onChange={(_, newValue) => {
                                        handleChangeRealizouAtendimento(
                                            newValue,
                                            setShowMotivoNaoAtendimento,
                                            onChange
                                        );
                                    }}
                                    value={value ? value : ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            data-testid="origemHisto"
                                            size="small"
                                            label="Origem da Histopatopatologia"
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Controller
                        name="tipos_achados"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    // disabled={disableOtherInputs}
                                    options={[
                                        'NIC I (Displasia leve)',
                                        'NIC II (Displasia moderada)',
                                        'NIC III (Displasia acentuada / Carcinoma in situ)',
                                        'Carcinoma Epidermóide Microinvasivo',
                                        'Carcinoma Epidermóide Invasivo',
                                        'Carcinoma Epidermóide impossível avaliar invasão'
                                    ]}
                                    onChange={(_, newValue) => {
                                        handleChangeRealizouAtendimento(
                                            newValue,
                                            setShowMotivoNaoAtendimento,
                                            onChange
                                        );
                                    }}
                                    value={value ? value : ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            data-testid="escamosa"
                                            size="small"
                                            label="Pré-Neoplásico Escamosa"
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Controller
                        name="tipos_achados"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    // disabled={disableOtherInputs}
                                    options={[
                                        'Adenocarcinoma in situ',
                                        'Adenocarcinoma invasor'
                                    ]}
                                    onChange={(_, newValue) => {
                                        handleChangeRealizouAtendimento(
                                            newValue,
                                            setShowMotivoNaoAtendimento,
                                            onChange
                                        );
                                    }}
                                    value={value ? value : ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            data-testid="glandular"
                                            size="small"
                                            label="Pré-Neoplásico Glandular"
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Controller
                        name="tipos_achados"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <Autocomplete
                                    // disabled={disableOtherInputs}
                                    options={['Terapêutica: Biópsia prévia de alto grau', 'Terapêutica: Critérios ver e tratar', 'Terapêutica: Investigação do canal']}
                                    onChange={(_, newValue) => {
                                        handleChangeRealizouAtendimento(
                                            newValue,
                                            setShowMotivoNaoAtendimento,
                                            onChange
                                        );
                                    }}
                                    value={value ? value : ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            data-testid="indicacaoEZT"
                                            size="small"
                                            label="Indicação da EZT?"
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        flexWrap="nowrap"
                        gap={2}
                    >
                        <Typography fontWeight="bold">Data da Coleta/Exame:</Typography>

                        <Controller
                            name="resultado_teste_hpv"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Autocomplete
                                        // disabled={disableOtherInputs}
                                        options={['Teste de HPV', 'Citologia', 'Colposcopia', 'Histopatologia']}
                                        onChange={(_, newValue) => {
                                            handleChangeRealizouAtendimento(
                                                newValue,
                                                setShowMotivoNaoAtendimento,
                                                onChange
                                            );
                                        }}
                                        value={value ? value : ''}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                data-testid="inputRealizouAtendimento"
                                                size="small"
                                                label="Procedimento"
                                            />
                                        )}
                                        sx={{ width: '140px' }}
                                    />
                                );
                            }}
                        />

                        <FormControl>
                            <RadioGroup
                                aria-labelledby="handleSetPeriod"
                                name="handleSetPeriod"
                                value={period}
                                onChange={handleSetPeriod}
                                row
                            >
                                <FormControlLabel
                                    value="date"
                                    data-testid="optionFiltrarDataColeta"
                                    control={<Radio />}
                                    label="Filtrar por Data"
                                />
                                <FormControlLabel
                                    value="period"
                                    data-testid="optionFiltrarPeriodoColeta"
                                    control={<Radio />}
                                    label="Filtrar por período"
                                />
                            </RadioGroup>
                        </FormControl>

                        {period === 'period' && (
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap="wrap"
                                flex="1"
                                rowGap={1}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={ptBR}
                                >
                                    <DatePicker
                                        disabled={disableOtherInputs}
                                        data-testid="periodDatepickerInitialDataColeta"
                                        onChange={(date) =>
                                            setDataColetaIni(
                                                date ? moment(date).format('DD-MM-YYYY') : null
                                            )
                                        }
                                        maxDate={new Date()}
                                        value={
                                            dtColetaIni
                                                ? moment(dtColetaIni, 'DD-MM-YYYY').toDate()
                                                : null
                                        }
                                        renderInput={(params) => (
                                            <StyledAsteriskTextField
                                                {...params}
                                                label="Data da Coleta/Exame"
                                                size="small"
                                                variant="outlined"
                                                sx={{ width: '180px' }}
                                                error={!!errorInputDate?.ini}
                                                helperText={errorInputDate?.ini}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={ptBR}
                                >
                                    <DatePicker
                                        disabled={disableOtherInputs}
                                        data-testid="periodDatepickerFinalDataColeta"
                                        onChange={(date) => {
                                            setDataColetaFinal(
                                                date ? moment(date).format('DD/MM/YYYY') : null
                                            );
                                        }}
                                        maxDate={new Date()}
                                        value={
                                            dtColetaFinal
                                                ? moment(dtColetaFinal, 'DD-MM-YYYY').toDate()
                                                : null
                                        }
                                        renderInput={(params) => (
                                            <StyledAsteriskTextField
                                                {...params}
                                                label="Fim da Data da Coleta"
                                                error={!!errorInputDateColeta}
                                                helperText={errorInputDateColeta.fin}
                                                size="small"
                                                variant="outlined"
                                                sx={{ width: '180px' }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                        )}

                        {period === 'date' && (
                            <>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={ptBR}
                                >
                                    <DatePicker
                                        data-testid="datepickerDataColeta"
                                        onChange={(date) => {
                                            setDataColetaIni(
                                                date ? moment(date).format('DD-MM-YYYY') : null
                                            );
                                            setDataColetaFinal(
                                                date ? moment(date).format('DD/MM/YYYY') : null
                                            );
                                        }}
                                        maxDate={new Date()}
                                        value={
                                            dtColetaIni && moment(dtColetaIni, 'DD-MM-YYYY').isValid()
                                                ? moment(dtColetaIni, 'DD-MM-YYYY').toDate()
                                                : null
                                        }
                                        disabled={disableOtherInputs}
                                        renderInput={(params) => (
                                            <StyledAsteriskTextField
                                                {...params}
                                                label="Data da Coleta"
                                                size="small"
                                                variant="outlined"
                                                sx={{ flex: 1 }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </>
                        )}
                    </Box>
                </Grid>


                <Grid item xs={12}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        flexWrap="wrap"
                        gap={2}
                    >
                        <Typography fontWeight="bold">Data do Resultado:</Typography>

                        <Controller
                            name="resultado_teste_hpv"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Autocomplete
                                        // disabled={disableOtherInputs}
                                        options={['Teste de HPV', 'Citologia', 'Colposcopia', 'Histopatologia']}
                                        onChange={(_, newValue) => {
                                            handleChangeRealizouAtendimento(
                                                newValue,
                                                setShowMotivoNaoAtendimento,
                                                onChange
                                            );
                                        }}
                                        value={value ? value : ''}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                data-testid="inputRealizouAtendimento"
                                                size="small"
                                                label="Procedimento"
                                            />
                                        )}
                                        sx={{ width: '140px' }}
                                    />
                                );
                            }}
                        />

                        <FormControl>
                            <RadioGroup
                                aria-labelledby="handleSetPeriod"
                                name="handleSetPeriod"
                                value={periodResultado}
                                onChange={handleSetPeriodResultado}
                                row
                            >
                                <FormControlLabel
                                    data-testid="optionFiltrarDataResultado"
                                    value="date"
                                    control={<Radio />}
                                    label="Filtrar por Data"
                                />
                                <FormControlLabel
                                    data-testid="optionFiltrarPeriodoResultado"
                                    value="period"
                                    control={<Radio />}
                                    label="Filtrar por período"
                                />
                            </RadioGroup>
                        </FormControl>

                        {periodResultado === 'period' && (
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap="wrap"
                                flex="1"
                                gap={1}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={ptBR}
                                >
                                    <DatePicker
                                        data-testid="periodDatepickerInitialDataResultado"
                                        disabled={disableOtherInputs}
                                        onChange={(date) => {
                                            setDataResultadoIni(
                                                date ? moment(date).format('DD-MM-YYYY') : null
                                            );
                                            handleSetFilter(
                                                `Início da Data do Resultado: ${moment(date).format(
                                                    'DD/MM/YYYY'
                                                )}`,
                                                'dt_resultado_inicio'
                                            );
                                        }}
                                        maxDate={new Date()}
                                        value={
                                            dtResultadoIni
                                                ? moment(dtResultadoIni, 'DD-MM-YYYY').toDate()
                                                : null
                                        }
                                        renderInput={(params) => (
                                            <StyledAsteriskTextField
                                                {...params}
                                                label="Início da Data do Resultado"
                                                size="small"
                                                sx={{ width: '200px' }}
                                                error={!!errorInputDateResultado?.ini}
                                                helperText={errorInputDateResultado?.ini}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={ptBR}
                                >
                                    <DatePicker
                                        disabled={disableOtherInputs}
                                        data-testid="periodDatepickerFinalDataResultado"
                                        onChange={(date) => {
                                            setDataResultadoFinal(
                                                date ? moment(date).format('DD-MM-YYYY') : null
                                            );
                                            handleSetFilter(
                                                `Fim da Data do Resultado: ${moment(date).format(
                                                    'DD/MM/YYYY'
                                                )}`,
                                                'dt_resultado_fim'
                                            );
                                        }}
                                        maxDate={new Date()}
                                        value={
                                            dtResultadoIni
                                                ? moment(dtResultadoFinal, 'DD-MM-YYYY').toDate()
                                                : null
                                        }
                                        renderInput={(params) => (
                                            <StyledAsteriskTextField
                                                {...params}
                                                label="Fim da Data do Resultado"
                                                error={!!errorInputDateResultado}
                                                helperText={errorInputDateResultado?.fin}
                                                size="small"
                                                variant="outlined"
                                                sx={{ width: '200px' }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                        )}

                        {periodResultado === 'date' && (
                            <>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={ptBR}
                                >
                                    <DatePicker
                                        data-testid="datepickerDataResultado"
                                        onChange={(date) => {
                                            setDataResultadoFinal(
                                                date ? moment(date).format('DD-MM-YYYY') : null
                                            );
                                            setDataResultadoIni(
                                                date ? moment(date).format('DD-MM-YYYY') : null
                                            );
                                            handleSetFilter(
                                                `Data do Resultado: ${moment(date).format(
                                                    'DD/MM/YYYY'
                                                )}`,
                                                'dt_resultado_inicio'
                                            );
                                        }}
                                        maxDate={new Date()}
                                        disabled={disableOtherInputs}
                                        value={
                                            dtResultadoFinal &&
                                                moment(dtResultadoFinal, 'DD-MM-YYYY').isValid()
                                                ? moment(dtResultadoFinal, 'DD-MM-YYYY').toDate()
                                                : null
                                        }
                                        renderInput={(params) => (
                                            <StyledAsteriskTextField
                                                {...params}
                                                label="Data do Resultado"
                                                size="small"
                                                variant="outlined"
                                                sx={{ flex: 1 }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </>
                        )}
                    </Box>
                </Grid>

                <Grid item xs={12} md={9}>
                    <Controller
                        name="search"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <TextField
                                    disabled={disableOtherInputs}
                                    data-testid="inputSearchByNameCpf"
                                    label="Pesquisar"
                                    placeholder="Pesquisar por Nome ou CPF da paciente"
                                    size="small"
                                    error={!!errors?.search}
                                    helperText={errors?.search?.message}
                                    value={value ? value : ''}
                                    onChange={(e) => {
                                        onChange(e.target.value);
                                        handleSetFilter(e.target.value, 'search');
                                    }}
                                    sx={{
                                        width: '100%',
                                        '.MuiOutlinedInput-root': {
                                            paddingRight: '0',
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    validationSubmit({
                                                        fnWhenFormIsValid,
                                                        estadosSelected,
                                                        municipiosSelected,
                                                        estabelecimentosSelected,
                                                        setErrorsFields,
                                                        setDisableOtherInputs,
                                                        watch,
                                                        setError,
                                                        clearErrors,
                                                    });
                                                }}
                                            >
                                                <SearchIcon sx={{ cursor: 'pointer', color: 'gray' }} />
                                            </Button>
                                        ),
                                    }}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={1.5}>
                    <Button
                        variant="contained"
                        fullWidth
                        type="submit"
                        data-testid="submitFilterReportsConsolidado"
                    >
                        FILTRAR
                    </Button>
                </Grid>
                <Grid item xs={12} md={1.5}>
                    <Button
                        variant="outlined"
                        color="error"
                        fullWidth
                        onClick={clearValues}
                        data-testid="clearFilterReportsConsolidado"
                    >
                        LIMPAR
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default FiltersReportsConsolidado;
