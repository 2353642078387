import moment from 'moment';
import { formatedMultiples } from '../../../../../utils/requestUtils/formatMultiples';

const useSetPayload = ({
  watch,
  estadosSelected = [],
  municipiosSelected = [],
  estabelecimentosSelected = [],
  dtColetaIni,
  dtColetaFinal,
  dtResultadoIni,
  dtResultadoFinal,
}: any) => {
  const setPayload = async () => {
    try {
      const formValues = watch ? watch() : {};

      const payload = {
        estados: estadosSelected?.length > 0 ? estadosSelected.join(', ') : undefined,
        municipios: municipiosSelected?.length > 0 ? municipiosSelected.join(', ') : undefined,
        estabelecimentoSaudeId: estabelecimentosSelected?.length > 0 ? estabelecimentosSelected.join(', ') : undefined,

        atendimento_teste_hpv: formValues?.atendimento_teste_hpv ?? undefined,
        resultado_teste_hpv: formValues?.resultado_teste_hpv ?? undefined,

        resultado_citologia: formValues?.resultado_citologia ?? undefined,
        tipos_achados: formValues?.tipos_achados ?? undefined,
        realizar_biopsia: formValues?.realizar_biopsia ?? undefined,
        origem_histopatologia: formValues?.origem_histopatologia ?? undefined,
        pre_neoplasico_escamosa: formValues?.pre_neoplasico_escamosa ?? undefined,
        pre_neoplasico_glandular: formValues?.pre_neoplasico_glandular ?? undefined,
        indicacao_ezt: formValues?.indicacao_ezt ?? undefined,

        dt_coleta_inicio: dtColetaIni ? moment(dtColetaIni, 'DD-MM-YYYY').format('YYYY-MM-DD') : undefined,
        dt_coleta_fim: dtColetaFinal ? moment(dtColetaFinal, 'DD-MM-YYYY').format('YYYY-MM-DD') : undefined,
        dt_resultado_inicio: dtResultadoIni ? moment(dtResultadoIni, 'DD-MM-YYYY').format('YYYY-MM-DD') : undefined,
        dt_resultado_fim: dtResultadoFinal ? moment(dtResultadoFinal, 'DD-MM-YYYY').format('YYYY-MM-DD') : undefined,

        procedimento: formValues?.procedimento ?? undefined,

        search: formValues?.search ?? undefined,
        size: formValues?.size ?? 25,
        page: formValues?.page ?? 1,
      };

      Object.keys(payload).forEach(key => {
        if (payload[key] === undefined) {
          delete payload[key];
        }
      });

      return payload;
    } catch (error) {
      console.error('Erro ao montar payload:', error);
      return {
        page: 1,
        size: 25
      };
    }
  };

  return { setPayload };
};

export default useSetPayload;