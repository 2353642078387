import {
    Box,
    Breadcrumbs,
    Container,
    Divider,
    Paper,
    Typography,
} from '@mui/material';
import { SideMenu } from '../../../components/SideMenu';

import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useStatesFields from '../../../components/MultipleFilters/hooks/useStatesFields';
import { LoadingContext } from '../../../context/LoadingContext';
import { Link, useLocation } from 'react-router-dom';
import {
    optionsMotivoNaoAtendida,
    optionsResultadoHPV,
    optionsTiposVirus,
} from '../../../utils/variables';
import api from '../../../api';
import moment from 'moment';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FiltersReportsConsolidado from './FiltersReportsConsolidado';
import TableReportsConsolidado from '../../../components/TableReports/TableConsolidado/TableReportsConsolidado';
import useGetDetailsFilters from './FiltersReportsConsolidado/hooks/useGetDetailsFilters';
import useRequestFilter from './FiltersReportsConsolidado/hooks/useRequestFilter';
import { getUserToken } from '../../../lib/auth';

const initialStateListFilter = [
    { inputName: 'estado', value: '' },
    { inputName: 'municipio', value: '' },
    { inputName: 'estabelecimento_saude', value: '' },
    { inputName: 'atendimento_teste_hpv', value: '' },
    { inputName: 'resultado_teste_hpv', value: '' },
    { inputName: 'dt_resultado_inicio', value: '' },
    { inputName: 'resultado_citologia', value: '' },
    { inputName: 'realizar_biopsia', value: '' },
    { inputName: 'origem_histopatologia', value: '' },
    { inputName: 'pre_neoplasico_escamosa', value: '' },
    { inputName: 'pre_neoplasico_glandular', value: '' },
    { inputName: 'indicacao_ezt', value: '' },
    { inputName: 'dt_coleta_inicio', value: '' },
    { inputName: 'dt_coleta_fim', value: '' },
    { inputName: 'dt_resultado_inicio', value: '' },
    { inputName: 'dt_resultado_fim', value: '' },
    { inputName: 'search', value: '' },
];

export const ReportsConsolidado = () => {
    const [tableData, setTableData] = useState<any[]>([]);
    const [showTableData, setShowTableData] = useState(false);
    const [payloadFromCharts, setPayloadFromCharts] = useState<any[]>();
    const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);
    const [listFilterShow, setListFilterShow] = useState<any[]>([]);
    const [period, setPeriod] = useState('period');
    const [periodResultado, setPeriodResultado] = useState('period');
    const [dtColetaIni, setDataColetaIni] = useState<any>(null);
    const [dtColetaFinal, setDataColetaFinal] = useState<any>(null);
    const [dtResultadoIni, setDataResultadoIni] = useState<any>(null);
    const [dtResultadoFinal, setDataResultadoFinal] = useState<any>(null);
    const location = useLocation();
    const origin = location?.state?.origin;
    const fromTable =
        origin === 'tables' || !!localStorage.getItem('requestFromTable');
    const payload = location?.state?.payload;
    const userToken = getUserToken();
    const permissaoId = getUserToken().permissao_atual?.id;

    const [selectedLaboratorio, setSelectedLaboratorio] = useState<any>();
    const [
        selectedOptinsProfissionalResponsavel,
        setSelectedOptinsProfissionalResponsavel,
    ] = useState<any>([]);

    const [disabledLaboratorioResultado, setDisabledLaboratorioResultado] =
        useState(false);

    const isPadrao =
        permissaoId === 1 ||
        permissaoId === 9 ||
        permissaoId === 10 ||
        permissaoId === 7;

    const isLaboratorio = userToken.permissao_atual.contexto === 'LABORATÓRIO';

    const isMunicipal = userToken.permissao_atual.id === 3;
    const isEstadual = userToken.permissao_atual.id === 4;

    const {
        estadosSelected,
        setEstadosSelected,
        municipiosSelected,
        setMunicipiosSelected,
        estabelecimentosSelected,
        setEstabelecimentosSelected,
        equipesSelected,
        setEquipesSelected,
        setDisableEquipeInput,
        setDisableInputEstado,
        disableInputEstado,
        setDisabledMunicipioInput,
        setDisabledEstabelecimentoInput,
        setOptionsMunicipios,
    } = useStatesFields();

    const handleSetListFilterShowFromCharts = (
        inputName: string,
        newValue: any
    ) => {
        setListFilterShow((prev) => {
            return [...prev, { inputName, value: newValue }];
        });
    };

    const handleSetFilter = (newValue: any, inputName: string) => {
        setListFilter((prev) =>
            prev.map((item) =>
                item.inputName === inputName ? { ...item, value: newValue ?? '' } : item
            )
        );
    };

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<any>({
        defaultValues: {
            page: 1,
            size: 25,
        },
    });

    const { setLoading } = useContext(LoadingContext);

    const { loadReportsConsolidado } = useRequestFilter({
        setTableData,
    });

    const { getMunicipioDetails } =
        useGetDetailsFilters();

    const setFiltersToRequestByTable = async () => {
        return new Promise(async (resolve) => {
            const periodo_inicio = localStorage.getItem('coleta_inicio') || undefined;
            const periodo_fim = localStorage.getItem('coleta_fim') || undefined;
            let payloadToRequest: any = {
                page: 1,
                size: 25,
                periodo_inicio,
                periodo_fim,
            };
            const payloadFromLocalStorage = JSON.parse(
                String(localStorage.getItem('payload'))
            );

            const selectedChartIsReason =
                !!localStorage.getItem('reasonNotProcedure');
            const params =
                location?.state?.payload?.params ||
                payloadFromLocalStorage?.params ||
                undefined;
            if (params) {
                payloadToRequest = {
                    ...payloadToRequest,
                    ...params,
                };
            }
            if (params?.periodo_inicio) {
                payloadToRequest = {
                    ...payloadToRequest,
                    dt_coleta_inicio: params.periodo_inicio,
                    dt_coleta_fim: params.periodo_fim,
                };
                handleSetListFilterShowFromCharts(
                    'periodo',
                    `Perido: De ${params.periodo_inicio} até ${params.periodo_fim}`
                );
                if (!selectedChartIsReason) {
                    //caso tiver o procedimento chama aqui
                    setValue('dtColetaIni', params.periodo_inicio);
                    setDataColetaIni(
                        moment(params.periodo_inicio, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    );
                    setValue('dtColetaFinal', params.periodo_fim);
                    setDataColetaFinal(
                        moment(params.periodo_fim, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    );
                }
            }
            if (params?.procedimento) {
                payloadToRequest = {
                    ...payloadToRequest,
                    procedimento: params?.procedimento,
                };
                setValue('realizou_atendimento', params?.procedimento ? 'Sim' : 'Não');
                setValue('procedimento', params?.procedimento ? 'Sim' : 'Não');
                handleSetListFilterShowFromCharts(
                    'realizou_atendimento',
                    `Procedimento: ${params?.procedimento ? 'Sim' : 'Não'}`
                );
            }

            const selectedChartIsResult = !!localStorage.getItem('result');
            const selectedChartIsTypeVirus = !!localStorage.getItem('typeVirus');
            const selectedChartOriginCollection =
                localStorage.getItem('collectionOrigin');
            const selectedChartTotalTests =
                location?.state?.panelClicked === 'totalTests';

            if (selectedChartTotalTests) {
                handleSetListFilterShowFromCharts('realizou_atendimento', 'Sim');
                setValue('realizou_atendimento', 'Sim');
                payloadToRequest = {
                    ...payloadToRequest,
                    realizou_atendimento: 'Sim',
                    atendimento_status: 'REALIZADO',
                };
            }

            if (location?.state?.table === 'faixa') {
                const dates = String(location.state.faixa?.[0])
                    ?.split('a')
                    ?.map((age) => age.trim());
                payloadToRequest = {
                    ...payloadToRequest,
                    idade_inicio: dates[0] || undefined,
                    idade_final: dates[1] || undefined,
                };

                setValue('idade_ini', dates[0]);
                dates[1] && setValue('idade_fin', dates[1]);

                handleSetListFilterShowFromCharts(
                    'idade_ini',
                    `Idade Início: ${dates[0]}`
                );
                dates[1] &&
                    handleSetListFilterShowFromCharts(
                        'idade_fin',
                        `Idade Fim: ${dates[1]}`
                    );
            }

            const filteredByState =
                !!payload?.params?.estados ||
                !!payloadFromLocalStorage?.params?.estados;

            const filteredByMunicipio =
                !!payload?.params?.municipios ||
                !!payloadFromLocalStorage?.params?.municipios;

            const filteredByEstabelecimento =
                !!payload?.params?.estabelecimentos ||
                !!payloadFromLocalStorage?.params?.estabelecimentos;

            const setDisplayFilterEstado = () => {
                handleSetListFilterShowFromCharts('estado', `Estado: PE`);
            };

            const setDisplayFilterMunicipio = async (valueMunicipio) => {
                const idsMunicipioFromCharts = String(valueMunicipio).split(',');
                const response = await api.get(`/municipios`, {
                    params: {
                        sigla: 'PE',
                    },
                });

                let arrOffMunicipiosLabel: any = [];

                idsMunicipioFromCharts.forEach((idFromChart) => {
                    const label = response.data.find(
                        (municipio) => String(municipio.id) === String(idFromChart).trim()
                    )?.nome;

                    arrOffMunicipiosLabel.push(label);
                });

                const labelsToSetFilter = arrOffMunicipiosLabel;

                if (labelsToSetFilter.length > 1) {
                    handleSetListFilterShowFromCharts('municipio', 'Municípios: Vários');
                } else {
                    handleSetListFilterShowFromCharts(
                        'municipio',
                        `Município: ${labelsToSetFilter[0]}`
                    );
                }
            };

            const setDisplayFilterEstabelecimentos = async ({
                valueEstabelecimentos,
                valueMunicipio,
            }) => {
                const idsEstabelecimentosFromCharts = String(
                    valueEstabelecimentos
                ).split(',');
                const response = await api.get(`/estabelecimentos-saude`, {
                    params: {
                        estado: 'PE',
                        municipio: valueMunicipio,
                    },
                });

                let arrOffEstabelecimentosLabel: any = [];

                idsEstabelecimentosFromCharts.forEach((idFromChart) => {
                    const label = response.data.data.find(
                        (estabelecimento) =>
                            String(estabelecimento.id) === String(idFromChart).trim()
                    )?.nome_fantasia;

                    arrOffEstabelecimentosLabel.push(label);
                });

                const labelsToSetFilter = arrOffEstabelecimentosLabel;

                if (labelsToSetFilter.length > 1) {
                    handleSetListFilterShowFromCharts(
                        'estabelecimento_saude',
                        'Estabelecimentos: Vários'
                    );
                } else {
                    handleSetListFilterShowFromCharts(
                        'estabelecimento_saude',
                        `Estabelecimento: ${labelsToSetFilter[0]}`
                    );
                }
            };

            if (
                filteredByState &&
                !filteredByMunicipio &&
                !filteredByEstabelecimento
            ) {
                const payloadEstado = payloadFromLocalStorage
                    ? payloadFromLocalStorage.params?.estados
                    : payload.params?.estados;

                payloadToRequest = {
                    ...payloadToRequest,

                    estado:
                        isPadrao || isMunicipal || isEstadual
                            ? 'PE'
                            : JSON.parse(payloadEstado).join(', '),
                };
                setPayloadFromCharts(payloadToRequest);
                setDisplayFilterEstado();
                setEstadosSelected(JSON.parse(payloadEstado));
            }

            if (
                filteredByState &&
                filteredByMunicipio &&
                !filteredByEstabelecimento
            ) {
                const valueEstado =
                    isPadrao || isEstadual || isMunicipal
                        ? 'PE'
                        : payloadFromLocalStorage
                            ? JSON.parse(payloadFromLocalStorage.params?.estados).join(', ')
                            : JSON.parse(payload.params?.estados).join(', ');

                const valueMunicipio =
                    isPadrao || isMunicipal
                        ? userToken.endereco.municipio_id
                        : payloadFromLocalStorage
                            ? payloadFromLocalStorage.params?.municipios
                            : payload.params?.municipios;

                payloadToRequest = {
                    ...payloadToRequest,
                    municipioId: valueMunicipio,
                    estado: valueEstado,
                };
                setEstadosSelected([valueEstado]);
                setMunicipiosSelected(
                    String(valueMunicipio)
                        .split(',')
                        .map((id) => id.trim())
                );

                setPayloadFromCharts(payloadToRequest);
                setDisplayFilterEstado();
                await setDisplayFilterMunicipio(valueMunicipio);
            }

            if (filteredByState && filteredByMunicipio && filteredByEstabelecimento) {
                // const acsData = await getAcsDetails(clickedCategory);
                const valueEstado =
                    isPadrao || isMunicipal || isEstadual
                        ? 'PE'
                        : payloadFromLocalStorage
                            ? JSON.parse(payloadFromLocalStorage.params?.estados).join(', ')
                            : JSON.parse(payload.params?.estados).join(', ');

                const valueMunicipio =
                    isPadrao || isMunicipal || isEstadual
                        ? userToken.endereco.municipio_id
                        : payloadFromLocalStorage
                            ? payloadFromLocalStorage.params?.municipios
                            : payload.params?.municipios;

                const valueEstabelecimentos = isPadrao
                    ? userToken.estabelecimentoId
                    : payloadFromLocalStorage
                        ? String(payloadFromLocalStorage.params?.estabelecimentos)
                        : String(payload.params?.estabelecimentos);

                payloadToRequest = {
                    ...payloadToRequest,
                    municipioId: valueMunicipio,
                    estado: valueEstado,
                    estabelecimentoSaudeId: valueEstabelecimentos,
                    // profissionalAcsId: acsData.data[0].id,
                };

                const nomeMunicipio = (await api.get(`/municipios/${valueMunicipio}`))
                    .data.nome;

                setEstadosSelected([valueEstado]);

                setMunicipiosSelected(
                    String(valueMunicipio)
                        .split(',')
                        .map((id) => id.trim())
                );
                setEstabelecimentosSelected(
                    String(valueEstabelecimentos)
                        .split(',')
                        .map((id) => Number(id.trim()))
                );
                setDisplayFilterEstado();
                await setDisplayFilterMunicipio(valueMunicipio);
                await setDisplayFilterEstabelecimentos({
                    valueEstabelecimentos,
                    valueMunicipio: nomeMunicipio,
                });
                setPayloadFromCharts(payloadToRequest);
            }

            if (selectedChartIsResult) {
                const value = localStorage.getItem('result');
                payloadToRequest = {
                    ...payloadToRequest,
                    resultado: value,
                };

                setValue(
                    'resultado',
                    optionsResultadoHPV.find((item) => item.value === value)
                );

                handleSetListFilterShowFromCharts(
                    'resultado',
                    `Resultado: ${optionsResultadoHPV.find((item) => item.value === value)?.label
                    }`
                );

                setLoading(false);
            }

            if (selectedChartIsReason) {
                const value = localStorage.getItem('reasonNotProcedure');
                setValue('dt_criado_inicio', payloadToRequest.dt_coleta_inicio);
                setValue('dt_criado_fim', payloadToRequest.dt_coleta_fim);
                payloadToRequest = {
                    ...payloadToRequest,
                    atendimento_status: value,
                    procedimento: 'false',
                    dt_coleta_inicio: undefined,
                    dt_coleta_fim: undefined,
                    dt_criado_inicio: payloadToRequest.dt_coleta_inicio,
                    dt_criado_fim: payloadToRequest.dt_coleta_fim,
                };
                setValue('dtColetaIni', undefined);
                setValue('dtColetaFinal', undefined);
                setValue('atendimento_status', value);
                setValue('realizou_atendimento', 'Não');

                handleSetListFilterShowFromCharts(
                    'realizou_atendimento',
                    'Realizou o atendimento?: Não'
                );
                handleSetListFilterShowFromCharts(
                    'atendimento_status',
                    `Motivo do Não Atendimento: ${optionsMotivoNaoAtendida.find((item) => item.value === value)?.label
                    }`
                );

                setLoading(false);
            }

            if (selectedChartOriginCollection) {
                const value = localStorage.getItem('collectionOrigin');
                payloadToRequest = {
                    ...payloadToRequest,
                    natureza: value,
                };

                setValue('natureza', value);

                handleSetListFilterShowFromCharts(
                    'natureza',
                    value === 'autocoleta'
                        ? 'Método de Coleta: Autocoleta'
                        : 'Método de Coleta: Profissional de Saúde'
                );

                setLoading(false);
            }

            if (selectedChartIsTypeVirus) {
                const value = localStorage.getItem('typeVirus');
                payloadToRequest = {
                    ...payloadToRequest,
                    tipo_virus: value,
                    resultado: 'POSITIVO',
                };

                setValue(
                    'resultado',
                    optionsResultadoHPV.find((item) => item.value === 'POSITIVO')
                );
                setValue(
                    'tipo_virus',
                    optionsTiposVirus.find((item) => item.value === value)
                );

                handleSetListFilterShowFromCharts('resultado', 'Resultado: Detectável');
                handleSetListFilterShowFromCharts(
                    'tipo_virus',
                    `Tipo de Vírus: ${optionsTiposVirus.find((item) => item.value === value)?.label
                    }`
                );

                setLoading(false);
            }
            resolve(payloadToRequest);
        });
    };

    const setFiltersWhenPadrao = async (userToken) => {
        setShowTableData(true);
        setDisableInputEstado(true);

        setDisabledMunicipioInput(true);

        return new Promise(async (resolve) => {
            let payload = {};
            setEstadosSelected(['PE']);

            const optionCidade = [
                {
                    id: userToken.endereco.municipio_id,

                    nome: userToken.endereco.municipio_nome,
                    codigo: userToken.endereco.municipio_code,
                    estadoSigla: userToken.endereco.estado,
                },
            ];

            setOptionsMunicipios(optionCidade);

            setMunicipiosSelected([optionCidade[0].id]);
            handleSetFilter('Estado: PE', 'estados');
            handleSetFilter(
                `Municipio: ${userToken.endereco.municipio_nome}`,
                'municipios'
            );
            if (isLaboratorio) {
                setDisabledLaboratorioResultado(true);
                setSelectedLaboratorio([userToken.estabelecimentoId]);
                payload = {
                    page: 1,
                    size: 25,
                    estados: 'PE',
                    municipios: optionCidade[0].id,
                    estabelecimentoLaudoId: userToken.estabelecimentoId,
                };

                handleSetFilter(
                    `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
                    'estabelecimentoLaudoId'
                );

                setListFilterShow([
                    {
                        inputName: 'estados',
                        value: 'Estado: PE',
                    },
                    {
                        inputName: 'municipios',
                        value: `Municipio: ${userToken.endereco.municipio_nome}`,
                    },
                    {
                        inputName: 'estabelecimentoLaudoId',
                        value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
                    },
                ]);
            } else {
                setDisabledEstabelecimentoInput(true);
                setEstabelecimentosSelected([userToken.estabelecimentoId]);
                payload = {
                    page: 1,
                    size: 25,
                    estados: 'PE',
                    municipios: optionCidade[0].id,
                    estabelecimentoSaudeId: userToken.estabelecimentoId,
                };

                handleSetFilter(
                    `Estabelecimento de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
                    'estabelecimento_saude'
                );

                setListFilterShow([
                    {
                        inputName: 'estados',
                        value: 'Estado: PE',
                    },
                    {
                        inputName: 'municipios',
                        value: `Municipio: ${userToken.endereco.municipio_nome}`,
                    },
                    {
                        inputName: 'estabelecimento_saude',
                        value: `Estabelecimento de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
                    },
                ]);
            }

            resolve(payload);
        });
    };

    const setFiltersWhenMunicipal = async (userToken) => {
        setShowTableData(false);
        setDisableInputEstado(true);
        setDisabledMunicipioInput(true);

        return new Promise(async (resolve) => {
            let payload = {};
            setEstadosSelected(['PE']);

            const municipio = await getMunicipioDetails(
                userToken.endereco.municipio_nome
            );

            setMunicipiosSelected([municipio[0].id]);

            payload = {
                page: 1,
                size: 25,
                estados: 'PE',
                municipios: municipio[0].id,
            };

            setListFilterShow([
                {
                    inputName: 'estados',
                    value: 'Estado: PE',
                },
                {
                    inputName: 'municipios',
                    value: `Municipio: ${userToken.endereco.municipio_nome}`,
                },
            ]);

            resolve(payload);
        });
    };

    const setFiltersWhenEstadual = async () => {
        setShowTableData(false);
        setDisableInputEstado(true);

        return new Promise(async (resolve) => {
            let payload = {};
            setEstadosSelected(['PE']);

            payload = {
                page: 1,
                size: 25,
                estados: 'PE',
            };

            setListFilterShow([
                {
                    inputName: 'estados',
                    value: 'Estado: PE',
                },
            ]);

            resolve(payload);
        });
    };
    console.log(userToken);
    const requestDataByUserPerfil = async () => {
        let payloadToReturn;
        if (
            userToken.permissao_atual.id === 1 ||
            userToken.permissao_atual.id === 10 ||
            userToken.permissao_atual.id === 7 ||
            userToken.permissao_atual.id === 9
        ) {
            setFiltersWhenPadrao(userToken).then((payload) => {
                payloadToReturn = payload;
                loadReportsConsolidado(payload);
                setLoading(false);
            });
        }

        if (userToken.permissao_atual.id === 3) {
            setFiltersWhenMunicipal(userToken).then((payload) => {
                payloadToReturn = payload;
                setLoading(false);
            });
        }

        if (userToken.permissao_atual.id === 4) {
            setFiltersWhenEstadual().then((payload) => {
                payloadToReturn = payload;
                setLoading(false);
            });
        }
        if (userToken.permissao_atual.id === 2) {
            setLoading(false);
        }

        return payloadToReturn;
    };

    useEffect(() => {
        if (fromTable) {
            setLoading(true);
            setFiltersToRequestByTable().then((payloadToRequest) => {
                setShowTableData(true);
                loadReportsConsolidado(payloadToRequest);
            });
            window.history.replaceState({}, '');
        } else {
            localStorage.removeItem('requestFromTable');
            localStorage.removeItem('payload');
            setLoading(true);
            requestDataByUserPerfil();
        }
    }, [origin]);

    return (
        <SideMenu>
            <Container>
                <Box mt={12}>
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        style={{ marginBottom: '30px' }}
                    >
                        Relatório Consolidado
                    </Typography>

                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                        style={{ marginBottom: '16px', marginTop: '16px' }}
                        sx={{ marginBottom: '24px' }}
                    >
                        <Link
                            style={{
                                textDecoration: 'none',
                                color: '#646464',
                            }}
                            to="/home"
                        >
                            Home
                        </Link>
                        <Typography color="text.primary">
                            {'Relatórios'}
                        </Typography>

                        <Typography key="3" color="text.primary">
                            Relatório Consolidado
                        </Typography>
                    </Breadcrumbs>

                    <Divider />
                </Box>

                <Box mt={5}>
                    <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            style={{ marginBottom: '30px', fontSize: '24px' }}
                        >
                            Filtros
                        </Typography>
                        <Divider />
                        <FiltersReportsConsolidado
                            selectedOptinsProfissionalResponsavel={selectedOptinsProfissionalResponsavel}
                            selectedLaboratorio={selectedLaboratorio}
                            watch={watch}
                            dtResultadoIni={dtResultadoIni}
                            setDataResultadoIni={setDataResultadoIni}
                            dtResultadoFinal={dtResultadoFinal}
                            setDataResultadoFinal={setDataResultadoFinal}
                            periodResultado={periodResultado}
                            setPeriodResultado={setPeriodResultado}
                            dtColetaIni={dtColetaIni}
                            setDataColetaIni={setDataColetaIni}
                            dtColetaFinal={dtColetaFinal}
                            setDataColetaFinal={setDataColetaFinal}
                            setListFilterToShow={setListFilterShow}
                            disableInputEstado={disableInputEstado}
                            period={period}
                            setPeriod={setPeriod}
                            listFilter={listFilter}
                            setListFilter={setListFilter}
                            setListFilterShow={setListFilterShow}
                            listFilterShow={listFilterShow}
                            initialStateListFilter={initialStateListFilter}
                            setTableData={setTableData}
                            control={control}
                            handleSubmit={handleSubmit}
                            setValue={setValue}
                            setError={setError}
                            clearErrors={clearErrors}
                            errors={errors}
                            estadosSelected={estadosSelected}
                            setEstadosSelected={setEstadosSelected}
                            setMunicipiosSelected={setMunicipiosSelected}
                            setEstabelecimentosSelected={setEstabelecimentosSelected}
                            municipiosSelected={municipiosSelected}
                            estabelecimentosSelected={estabelecimentosSelected}
                            setShowTableData={setShowTableData}
                            showTableData={showTableData}
                            equipesSelected={equipesSelected}
                            setEquipesSelected={setEquipesSelected}
                            setDisableEquipeInput={setDisableEquipeInput}
                        />

                        <TableReportsConsolidado
                            selectedOptinsProfissionalResponsavel={
                                selectedOptinsProfissionalResponsavel
                            }
                            dtResultadoIni={dtResultadoIni}
                            dtResultadoFinal={dtResultadoFinal}
                            tableData={tableData}
                            setValue={setValue}
                            watch={watch}
                            setTableData={setTableData}
                            estadosSelected={estadosSelected}
                            municipiosSelected={municipiosSelected}
                            estabelecimentosSelected={estabelecimentosSelected}
                            showTableData={showTableData}
                            dtColetaFinal={dtColetaFinal}
                            dtColetaIni={dtColetaIni}
                        />
                    </Paper>
                </Box>
            </Container>
        </SideMenu>
    );
};

export default ReportsConsolidado;
