import moment from 'moment';

const useSetPayload = ({
  watch,
  estadosSelected,
  municipiosSelected,
  estabelecimentosSelected,
  equipesSelected,
  dtColetaIni,
  dtColetaFinal,
  selectedResultado,
  laboratoriosSelected,
}: any) => {
  const resultadoValue = selectedResultado?.map((result) => {
    if (result.label === 'Com Alterações Epiteliais') {
      return 'Alterações Epiteliais';
    } else {
      return result.label;
    }
  });
  const setPayload = () => {
    let payload: any;
    return new Promise((resolve, reject) => {
      const arrayIdsLaboratorios = laboratoriosSelected?.map(
        (lab) => lab.value
      );
      payload = {
        estado:
          estadosSelected.length > 0 ? estadosSelected.join(', ') : undefined,
        municipioId:
          municipiosSelected.length > 0
            ? municipiosSelected.join(', ')
            : undefined,
        estabelecimentoSaudeId:
          estabelecimentosSelected.length > 0
            ? estabelecimentosSelected.join(', ')
            : undefined,
        laboratorioLaudoId:
          arrayIdsLaboratorios?.length > 0
            ? arrayIdsLaboratorios?.join(', ')
            : undefined,
        profissionalResultadoId: watch('profissionalResultadoId')
          ?.idprofissional,
        tipo: 'Reflexo',
        resultado:
          resultadoValue?.length > 0 ? resultadoValue?.join(',') : undefined,

        periodo_inicio: dtColetaIni
          ? moment(dtColetaIni, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : undefined,
        periodo_fim: dtColetaFinal
          ? moment(dtColetaFinal, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : undefined,
        motivo: watch().motivo ?? undefined,
        search: watch().search ?? undefined,
        size: watch().size,
        page: watch().page,
      };

      resolve(payload);
    });
  };

  return { setPayload };
};

export default useSetPayload;
