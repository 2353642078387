export const optionsResultadoHPV = [
  { value: 'INVALIDO', label: 'Inconclusivo' },
  {
    value: 'NEGATIVO_INDETECTADO',
    label: 'Não Detectável',
  },
  {
    value: 'POSITIVO',
    label: 'Detectável',
  },
];

export const optionsTiposVirus = [
  { value: 'hpv_16', label: 'HPV 16' },
  {
    value: 'hpv_18',
    label: 'HPV 18',
  },
  {
    value: 'hpv_outros',
    label: 'Outros',
  },
];

export const optionsMotivoExame = [
  { value: 'RASTREAMENTO', label: 'Rastreamento' },
  {
    value: 'REPETICAO',
    label: 'Repetição (exame alterado ASCUS/Baixo grau)',
  },
  {
    value: 'SEGUIMENTO',
    label: 'Seguimento (pós diagnóstico colposcópico / tratamento)',
  },
];
export const optionsInspecao = [
  { value: 'NORMAL', label: 'Normal' },
  {
    value: 'NAO_VISUALIZADO',
    label: 'Colo não visualizado',
  },
  {
    value: 'ALTERADO',
    label: 'Alterado',
  },
  {
    value: 'AUSENTE',
    label: 'Ausente (anomalias congênitas ou retirado cirurgicamente)',
  },
];

export const optionsMotivoNaoAtendida = [
  { value: 'NAO_REALIZOU', label: 'Compareceu mas não realizou o exame' },
  {
    value: 'MENSTRUADA',
    label: 'Estava Menstruada',
  },
  {
    value: 'NAO_FAIXA_ETARIA',
    label: 'Não está na faixa etária',
  },
  {
    value: 'HISTERECTOMIZADA',
    label: 'Histerectomizada por patologia benigna',
  },
  {
    value: 'OUTRO',
    label: 'Outro',
  },
];
