import { Box, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from '../../../../api';
import { AreaChart } from '../components/areaChart';
import { ChartCard } from '../../../../components/Charts/chartCard';
import { GlandularPreNeoplastic } from '../components/glandularPreNeoplastic';
import { HistopathologyOrigin } from '../components/histopathologyOrigin';
import { SquamousPreNeoplastic } from '../components/squamousPreNeoplastic';
import FiltersVisaoGeral from '../components/filters/FilterVisaoGeral';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import { TableCard } from '../../../../components/TableExamesHistopatologia/TableCard';
import TableExamesHistopatologia from '../../../../components/TableExamesHistopatologia/TableHistopatologia/TableExamesHistopatologia';
import styles from './styles.module.css';
import { FunnelChart } from '../components/funnelChart';
import { LoadingContext } from '../../../../context/LoadingContext';
import { get } from 'http';
import { useParams } from 'react-router-dom';
import EmptyData from '../../../../components/Charts/emptyData';
import { getUserToken } from '../../../../lib/auth';
import { set } from 'date-fns';
import useStatesFields from '../../../../components/MultipleFilters/hooks/useStatesFields';

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento', value: '' },
  { inputName: 'equipe', value: '' },
  { inputName: 'origem', value: 'Biópsia' },
  { inputName: 'periodo_inicio', value: '' },
  { inputName: 'periodo_fim', value: '' },
];

export function VisaoGeral() {
  const [listFilter, setListFilter] = useState<any>(initialStateListFilter);

  const [estadosSelected, setEstadosSelected] = useState<any[]>([]);
  const [municipiosSelected, setMunicipiosSelected] = useState<any>([]);
  const [estabelecimentosSelected, setEstabelecimentosSelected] = useState<any>(
    []
  );
  const [periodToDisplay, setPeriodToDisplay] = useState('Todos os períodos');
  const [valueInputPeriod, setValueInputPeriod] = useState<any>('Todos');

  const [showListFilter, setShowListFilter] = useState<any>([]);
  const { control } = useForm();
  const [totalTestsPerMonth, setTotalTestsPerMonth] = useState<any>([]);
  const [categories, setCategories] = useState<any>(['NÃO HÁ DADOSS']);

  const [benignLesionsData, setBenignLesionsData] = useState<
    { x: string; y: number }[]
  >([]);
  const [glandularPreNeoplasticData, setGlandularPreNeoplasticData] = useState<
    number[]
  >([]);
  const [
    glandularPreNeoplasticCategories,
    setGlandularPreNeoplasticCategories,
  ] = useState<string[][]>([]);
  const [histopathologyOriginData, setHistopathologyOriginData] = useState<
    number[]
  >([]);
  const [histopathologyOriginLabels, setHistopathologyOriginLabels] = useState<
    string[]
  >([]);
  const [squamousPreNeoplasticData, setSquamousPreNeoplasticData] = useState<
    number[]
  >([]);
  const [histopatologiaPage, setHistopatologiaPage] = useState(1);
  const [histopatologiaRowsPerPage, setHistopatologiaRowsPerPage] =
    useState(10);
  const [histopatologiaDataTable, setHistopatologiaDataTable] = useState({
    data: [],
    totals: {},
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 25,
    localizacaoType: '',
  });

  const [labelItemsOrigem, setLabelItemsOrigem] = useState<String>('');

  const [payload, setPayload] = useState<any>();
  const [total, setTotal] = useState<any>();
  const { setLoading } = useContext(LoadingContext);
  const [countLoading, setCountLoading] = useState(0);
  const [origemSelecionada, setOrigemSelecionada] = useState('Biópsia');
  const [filtersData, setFiltersData] = useState<any>();

  const { setEstadosOptions, setOptionsMunicipios } = useStatesFields();

  const emptyDataBenignLessions = !benignLesionsData.find((item) => item.y > 0);
  const emptyDataNeoplasicLesions = !glandularPreNeoplasticData.find(
    (item) => item > 0
  );
  const emptyDataHistopathologyOrigin = !histopathologyOriginData.find(
    (item) => item > 0
  );

  const emptySquamousPreNeoplastic = !squamousPreNeoplasticData.find(
    (item) => item > 0
  );

  const getTestesPerMonth = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/histopatologia/panel/quantity-month',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
                origem: payload.params.origem,
              },
        }
      );

      const data = response.data.data.data;
      const total = response.data.data.total;

      if (!Array.isArray(data) || data.length === 0) {
        setCategories(['NÃO HÁ DADOS']);
        setTotalTestsPerMonth([]);
        setTotal(null);
      } else {
        setCategories(
          data.map((item, index) => {
            if (index === 0 || item.month === 'Jan') {
              return [
                `${String(item.month).toUpperCase()}`,
                `${String(item.year)}`,
              ];
            } else {
              return String(item.month).toUpperCase();
            }
          })
        );

        setTotalTestsPerMonth(data.map((item) => Number(item.count)));

        setTotal(total);
      }
      setFiltersData(response.data.filters);
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      setCountLoading((prev) => prev + 1);
      console.error('Error fetching data:', error);
      setCategories(['NÃO HÁ DADOS']);
      setTotalTestsPerMonth([]);
      setTotal(null);
    }
  };

  // useEffect(() => {
  //   getTestesPerMonth(false, {}); // Chama a função para buscar os dados
  // }, []);

  /*
  useEffect(() => {
    if (payload?.params?.origem) {
      setOrigemSelecionada(payload.params.origem);
    } else {
      setOrigemSelecionada('Biópsia');
    }
  }, [payload]);
*/

  useEffect(() => {
    if (isMunicipal) {
      setLoading(true);
    }
    if (countLoading >= 6) {
      setLoading(false);
    }
  }, [countLoading]);

  const getBenignLesionsChart = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/histopatologia/panel/benign-lesions-totals',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
                origem: payload.params.origem,
              },
        }
      );
      const data = response.data.data; // Acessa o array de dados

      if (!Array.isArray(data) || data.length === 0) {
        setBenignLesionsData([]);
      } else {
        setBenignLesionsData(
          data.map((item) => ({ x: item.resultType, y: Number(item.total) }))
        );
      }

      setCountLoading((prev) => prev + 1);
    } catch (error) {
      setCountLoading((prev) => prev + 1);

      console.error('Error fetching data:', error);
      setBenignLesionsData([]);
    }
  };

  const splitResultType = (resultType) => {
    const parts = resultType.split(' ');
    if (parts.length <= 2) {
      return parts;
    }
    return [parts.slice(0, -1).join(' '), parts[parts.length - 1]];
  };

  const getGlandularPreNeoplastic = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/histopatologia/panel/glandular-pre-neoplasica-totals',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
                origem: payload.params.origem,
              },
        }
      );
      const data = response.data.data; // Acessa o array de dados

      if (!Array.isArray(data) || data.length === 0) {
        setGlandularPreNeoplasticData([]);
        setGlandularPreNeoplasticCategories([]);
      } else {
        setGlandularPreNeoplasticData(data.map((item) => Number(item.total)));
        setGlandularPreNeoplasticCategories(
          data.map((item) => splitResultType(item.resultType))
        );
      }
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      setCountLoading((prev) => prev + 1);

      console.error('Error fetching data:', error);
      setGlandularPreNeoplasticData([]);
      setGlandularPreNeoplasticCategories([]);
    }
  };

  //logica de get para HistopathologyOrigin - /exames/histopatologia/panel/origin-percentages
  const getHistopathologyOrigin = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/histopatologia/panel/origin-percentages',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
              },
        }
      );

      const data = response.data.data; // Acessa o array de dados

      if (!Array.isArray(data) || data.length === 0) {
        setHistopathologyOriginData([]);
        setHistopathologyOriginLabels([]);
        // setEmptyDataHistopathologyOrigin(true);
      } else {
        const allTotalsNull = data.every((item) => item.total === null);
        if (allTotalsNull) {
          setHistopathologyOriginData([]);
          setHistopathologyOriginLabels([]);
          // setEmptyDataHistopathologyOrigin(true);
        } else {
          setHistopathologyOriginData(
            data.map((item) => Number(item.percentage ?? 0))
          );
          setHistopathologyOriginLabels(
            data.map((item) => `${item.resultType} (${item.total ?? 0})`)
          );
          // setEmptyDataHistopathologyOrigin(false);
        }
      }
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      setCountLoading((prev) => prev + 1);
      console.error('Error fetching data:', error);
      setHistopathologyOriginData([]);
      setHistopathologyOriginLabels([]);
      // setEmptyDataHistopathologyOrigin(true);
    }
  };

  // useEffect(() => {
  // Chame a função getHistopathologyOrigin aqui com os parâmetros apropriados
  //   getHistopathologyOrigin(false, {
  //     params: { estados: 'PE', municipios: '1752' },
  //   });
  // }, []);

  // const [emptyDataHistopathologyOrigin, setEmptyDataHistopathologyOrigin] =
  //   useState(false);

  // console.log(emptyDataHistopathologyOrigin);

  const getSquamousPreNeoplastic = async (cleared, payload) => {
    try {
      const response = await api.get(
        '/dashboard/exames/histopatologia/panel/escamosa-pre-neoplasica-totals',
        {
          params: cleared
            ? {}
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
                origem: payload.params.origem,
              },
        }
      );

      const data = response.data.data; // Acessa o array de dados

      if (!Array.isArray(data) || data.length === 0) {
        setSquamousPreNeoplasticData([]);
      } else {
        setSquamousPreNeoplasticData(data.map((item) => Number(item.total)));
      }
      setCountLoading((prev) => prev + 1);
    } catch (error) {
      setCountLoading((prev) => prev + 1);

      console.error('Error fetching data:', error);
      setSquamousPreNeoplasticData([]);
    }
  };

  const getSubtitle = () => {
    if (origemSelecionada === 'Biópsia') {
      return 'Mulheres com Colposcopia com Achados Menores, Maiores ou Segestivo de Invasão x Histopatologia Realizadas';
    } else if (origemSelecionada === 'Peça Cirúrgica') {
      return 'Mulheres com Tratamento LIEAG x Histopatologia Peça Cirúrgica Realizadas';
    }
    return '';
  };

  interface PaginationState {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
  }

  const [paginationState, setPaginationState] = useState<PaginationState>({
    currentPage: 1,
    itemsPerPage: 25,
    totalItems: 0,
  });

  const getColposcopiaDataTable = async (
    cleared: boolean,
    payload: any,
    newPaginationState: PaginationState
  ) => {
    try {
      setLoading(true);
      const response = await api.get(
        '/dashboard/exames/histopatologia/panel/multi-table',
        {
          params: cleared
            ? {
                origem: 'Biópsia',
                page: newPaginationState.currentPage,
                pageSize: newPaginationState.itemsPerPage,
              }
            : {
                ...payload.params,
                estados: payload.params.estados,
                municipios: payload.params.municipios,
                estabelecimentos: payload.params.estabelecimentos,
                origem: payload.params.origem,
                page: newPaginationState.currentPage,
                limit: newPaginationState.itemsPerPage,
              },
        }
      );

      const { data, totals, totalItems } = response.data;

      setHistopatologiaDataTable({
        data,
        totals,
        totalItems,
        currentPage: newPaginationState.currentPage,
        itemsPerPage: newPaginationState.itemsPerPage,
        localizacaoType: response.data.localizacaoType,
      });

      setPaginationState({
        currentPage: newPaginationState.currentPage,
        itemsPerPage: newPaginationState.itemsPerPage,
        totalItems: totalItems,
      });

      setCountLoading((prev) => prev + 1);
    } catch (error) {
      console.error('Error fetching histopatologia data:', error);
      setCountLoading((prev) => prev + 1);
    } finally {
      setLoading(false);
    }
  };

  const handleHistopatologiaPageChange = (newPage: number) => {
    setPaginationState((prevState) => {
      const newState = {
        ...prevState,
        currentPage: newPage,
      };
      getColposcopiaDataTable(false, payload, newState);
      return newState;
    });
  };

  const handleHistopatologiaRowsPerPageChange = (newRowsPerPage: number) => {
    setPaginationState((prevState) => {
      const newState = {
        ...prevState,
        currentPage: 1,
        itemsPerPage: newRowsPerPage,
      };
      getColposcopiaDataTable(false, payload, newState);
      return newState;
    });
  };

  const getAllCharts = ({ cleared, payload }: any) => {
    setLabelItemsOrigem(origemSelecionada);
    setCountLoading(0);
    setTotalTestsPerMonth([]);
    setCategories([]);
    setTotal('');
    getTestesPerMonth(cleared, payload);
    getBenignLesionsChart(cleared, payload);
    getGlandularPreNeoplastic(cleared, payload);
    getHistopathologyOrigin(cleared, payload);
    getSquamousPreNeoplastic(cleared, payload);
    getColposcopiaDataTable(cleared, payload, paginationState);
  };

  const userToken = getUserToken();

  const isSuperAdmin = userToken.permissao_atual.nome === 'SuperAdmin';
  const isPadrao = userToken.permissao_atual.id === 1;
  const isMunicipal = userToken.permissao_atual.id === 3;
  const isEstadual = userToken.permissao_atual.id === 4;
  const isAdminLaboratorio = userToken.permissao_atual.id === 6;

  const setDefaultEstado = ({ sigla }) => {
    setEstadosOptions([
      {
        sigla: sigla,
      },
    ]);
    setEstadosSelected([sigla]);
  };

  const setDefaultMunicipio = ({ municipioId, municipioNome }) => {
    setOptionsMunicipios({
      id: municipioId,
      nome: municipioNome,
    });
    setMunicipiosSelected([municipioId]);
  };

  useEffect(() => {
    if (isSuperAdmin) {
      setCountLoading(9);
      return;
    }

    if (isEstadual) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        { inputName: 'estabelecimento', value: '' },
        { inputName: 'equipe', value: '' },
        {
          inputName: 'periodo_inicio',
          value: 'Período: Todos os períodos',
        },
        { inputName: 'periodo_fim', value: '' },
        { inputName: 'origem', value: 'Origem: Biópsia' },
      ];

      setListFilter(listFilter);
      setDefaultEstado({
        sigla: userToken.estabelecimento_saude.endereco.municipio.estado.sigla,
      });

      setCountLoading(9);
    }

    if (isMunicipal) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: `Municípios: ${userToken.estabelecimento_saude.endereco.municipio.nome}`,
        },
        { inputName: 'estabelecimento', value: '' },
        { inputName: 'equipe', value: '' },
        { inputName: 'periodo_inicio', value: 'Período: Todos os períodos' },
        { inputName: 'periodo_fim', value: '' },
        { inputName: 'origem', value: 'Origem: Biópsia' },
      ];

      setListFilter(listFilter);
      setShowListFilter(listFilter);
      setDefaultEstado({
        sigla: userToken.estabelecimento_saude.endereco.municipio.estado.sigla,
      });
      setDefaultMunicipio({
        municipioId: String(
          userToken.estabelecimento_saude.endereco.municipio.id
        ),
        municipioNome: userToken.estabelecimento_saude.endereco.municipio.nome,
      });
    }

    if (isAdminLaboratorio) {
      const listFilter = [
        {
          inputName: 'estado',
          value: `Estados: ${userToken.estabelecimento_saude.endereco.municipio.estado.sigla}`,
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        { inputName: 'estabelecimento', value: '' },
        { inputName: 'equipe', value: '' },
        { inputName: 'periodo_inicio', value: 'Período: Todos os períodos' },
        { inputName: 'periodo_fim', value: '' },
        { inputName: 'origem', value: 'Origem: Biópsia' },
      ];

      setListFilter(listFilter);
      setDefaultEstado({
        sigla: userToken.estabelecimento_saude.endereco.municipio.estado.sigla,
      });

      setCountLoading(9);
    }

    if (isMunicipal) {
      getAllCharts({
        cleared: false,
        payload: {
          params: {
            ...payload?.params,
            origem: 'Biópsia',
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (totalTestsPerMonth.length > 0) {
      setTotal(totalTestsPerMonth.reduce((prev, curr) => prev + curr, 0));
    } else {
      setTotal(null);
    }
  }, [totalTestsPerMonth]);
  // useEffect(() => {
  //   if (payload) {
  //     getTestesPerMonth(false, payload);
  //     getBenignLesionsChart(false, payload);
  //     getGlandularPreNeoplastic(false, payload);
  //     getHistopathologyOrigin(false, payload);
  //     getSquamousPreNeoplastic(false, payload);
  //     getColposcopiaDataTable(false, payload);
  //   }
  // }, [payload]);
  return (
    <Box mt="31px">
      <Box>
        <FiltersVisaoGeral
          setPaginationState={setPaginationState}
          setTotal={setTotal}
          setBenignLesionsData={setBenignLesionsData}
          setGlandularPreNeoplasticData={setGlandularPreNeoplasticData}
          setHistopathologyOriginData={setHistopathologyOriginData}
          setSquamousPreNeoplasticData={setSquamousPreNeoplasticData}
          setHistopatologiaDataTable={setHistopatologiaDataTable}
          setPeriodToDisplay={setPeriodToDisplay}
          setTotalTestsPerMonth={setTotalTestsPerMonth}
          payload={payload}
          valueInputPeriod={valueInputPeriod}
          setValueInputPeriod={setValueInputPeriod}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          setEstadosSelected={setEstadosSelected}
          setMunicipiosSelected={setMunicipiosSelected}
          municipiosSelected={municipiosSelected}
          estadosSelected={estadosSelected}
          estabelecimentosSelected={estabelecimentosSelected}
          control={control}
          setShowListFilter={setShowListFilter}
          setPayload={setPayload}
          getAllCharts={getAllCharts}
          setListFilter={setListFilter}
          initialStateListFilter={initialStateListFilter}
          listFilter={listFilter}
          setOrigemSelecionada={setOrigemSelecionada}
        />
      </Box>

      <Box display="flex" flexDirection="column" gap="2px">
        {/* <Typography variant="h5" fontWeight="bold" fontSize="24px">
          Exames de Histopatologia - Visão Geral
        </Typography> */}
        {/* <Typography variant="h6" fontWeight="bold" fontSize="20px">
          Últimos 12 meses
        </Typography>
        <Typography variant="body1">
          Data de Atualização: 29/01/2024 16:15
        </Typography> */}
        <DisplayFilter style={{ marginTop: '24px', marginBottom: '37px' }}>
          <p style={{ margin: 0, width: '138px' }}>Filtros aplicados:</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {showListFilter.map((item) => (
              <>
                {item.value ? (
                  <>
                    <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                  </>
                ) : undefined}
              </>
            ))}
          </div>
        </DisplayFilter>
      </Box>

      <div className={styles.testsDoneCard}>
        <div className={styles.testsDoneCardTitle}>
          <Typography variant="h5" fontWeight="bold">
            Exames de Histopatologia ({origemSelecionada})<br />
            Quantidade de Exames Realizados
          </Typography>
          <p style={{ margin: 0, fontSize: '12px', color: '#9E9E9E' }}>
            {valueInputPeriod === 'Todos' || !valueInputPeriod
              ? 'Todos os períodos'
              : valueInputPeriod}
          </p>
          <Typography variant="h5" fontWeight="bold">
            {total ? total?.toLocaleString('pt-BR') : ''}
          </Typography>
        </div>

        <AreaChart
          data={totalTestsPerMonth}
          categories={categories}
          payload={null}
        />

        <p
          style={{
            marginBottom: '16px',
            marginRight: '16px',
            bottom: '0',
            right: '0',
            position: 'absolute',
            fontSize: '10px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          Fonte dos Dados: JORDANA
        </p>
      </div>

      <Grid item xs={12}>
        <TableCard
          title={`Exames de Histopatologia (${origemSelecionada})`}
          subtitle="Lesões de Caráter Benigno"
          fullWidth
          valueInputPeriod={valueInputPeriod}
        >
          <div style={{ position: 'relative' }}>
            {emptyDataBenignLessions && (
              <div
                style={{
                  marginTop: '-49px',
                  marginBottom: '179px',
                  position: 'absolute',
                  left: '40%',
                  top: '200px',
                  zIndex: '1',
                }}
              >
                <EmptyData />
              </div>
            )}
          </div>

          <FunnelChart data={benignLesionsData} />

          <p
            style={{
              marginBottom: '16px',
              marginRight: '16px',
              bottom: '0',
              right: '0',
              position: 'absolute',
              fontSize: '10px',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            Fonte dos Dados: JORDANA
          </p>
        </TableCard>
      </Grid>

      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6.5}>
            <ChartCard
              title={`Exames de Histopatologia (${origemSelecionada})`}
              subtitle="Lesões de Caráter Neoplásico - Pré-Neoplásico Glandular"
              valueInputPeriod={valueInputPeriod}
              fullWidth
            >
              {emptyDataNeoplasicLesions && (
                <div
                  style={{
                    marginTop: '-49px',
                    marginBottom: '179px',
                    position: 'absolute',
                    left: '30%',
                    top: '200px',
                    zIndex: '1',
                  }}
                >
                  <EmptyData />
                </div>
              )}
              <GlandularPreNeoplastic
                data={glandularPreNeoplasticData}
                categories={glandularPreNeoplasticCategories}
              />
            </ChartCard>
          </Grid>

          <Grid item xs={5.5}>
            <ChartCard
              title="Exames de Histopatologia Origem*"
              subtitle=""
              valueInputPeriod={valueInputPeriod}
              fullWidth
            >
              {emptyDataHistopathologyOrigin && (
                <div
                  style={{
                    marginTop: '-49px',
                    marginBottom: '179px',
                    position: 'absolute',
                    left: '30%',
                    top: '200px',
                    zIndex: '1',
                  }}
                >
                  <EmptyData />
                </div>
              )}
              <HistopathologyOrigin
                data={histopathologyOriginData}
                labels={histopathologyOriginLabels}
                colors={['#0079AD', '#01AEFA']}
              />
            </ChartCard>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ChartCard
            title={`Exames de Histopatologia (${origemSelecionada})`}
            subtitle="Lesões de Caráter Neoplásico - Pré-Neoplásico Escamosa"
            valueInputPeriod={valueInputPeriod}
            fullWidth
          >
            {emptySquamousPreNeoplastic && (
              <div
                style={{
                  marginTop: '-49px',
                  marginBottom: '179px',
                  position: 'absolute',
                  left: '40%',
                  top: '150px',
                  zIndex: '1',
                }}
              >
                <EmptyData />
              </div>
            )}
            <SquamousPreNeoplastic data={squamousPreNeoplasticData} />
          </ChartCard>
        </Grid>

        <Grid item xs={12}>
          <TableCard
            title={`Exames de Histopatologia (${origemSelecionada})`}
            subtitle={getSubtitle()}
            fullWidth
            valueInputPeriod={valueInputPeriod}
          >
            <TableExamesHistopatologia
              data={histopatologiaDataTable.data}
              totals={histopatologiaDataTable.totals}
              origemSelecionada={String(origemSelecionada)}
              totalItems={paginationState.totalItems}
              currentPage={paginationState.currentPage}
              itemsPerPage={paginationState.itemsPerPage}
              onPageChange={handleHistopatologiaPageChange}
              onRowsPerPageChange={handleHistopatologiaRowsPerPageChange}
              localizacaoType={histopatologiaDataTable.localizacaoType}
            />

            <p
              style={{
                marginBottom: '16px',
                marginRight: '16px',
                bottom: '0',
                right: '0',
                position: 'absolute',
                fontSize: '10px',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              Fonte dos Dados: JORDANA
            </p>
          </TableCard>
        </Grid>
      </Grid>
    </Box>
  );
}
