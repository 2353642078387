import * as Yup from 'yup';

export const schemaVariation = (
  hasVinculo,
  canUpdateVinculo,
  disableInputsVinculo,
  profissionalNotHasEquipe
) => {
  const schema = Yup.object({
    cns: Yup.number()
      .positive()
      .integer('Campo Obrigatório')
      .required('Campo Obrigatório')
      .typeError('Campo Obrigatório'),
    nome: Yup.string().required('Campo Obrigatório'),
    apelido: Yup.string().nullable(),
    nome_mae: Yup.string().required('Campo Obrigatório'),
    cpf: Yup.string()
      .test('cpf', 'CPF não tem 11 digítos', (cpf: any) => {
        return cpf?.replace(/\D/g, '').length === 11 || !cpf;
      })
      .required('Campo Obrigatório'),
    dt_nascimento: Yup.date().required('Campo Obrigátorio').nullable(),
    nacionalidade: Yup.string().nullable(),
    raca: Yup.string().nullable(),
    escolaridade: Yup.string().nullable(),
    orientacao: Yup.string().nullable(),
    identidade_genero: Yup.string().nullable(),
    endereco: Yup.object({
      cep: Yup.string()
        .test('cep', 'CEP não tem 8 digítos ', (cep: any) => {
          return cep?.replace(/\D/g, '').length === 8 || !cep;
        })
        .nullable(),
      logradouro: Yup.string().nullable(),
      numero: Yup.string().nullable(),
      complemento: Yup.string().nullable(),
      referencia: Yup.string().nullable(),
      zona: Yup.string().nullable(),
      bairro: Yup.string().nullable(),
      municipio: Yup.object({
        id: Yup.number().nullable().required('Campo Obrigatório.'),
        nome: Yup.string().nullable(),
        estado: Yup.object()
          .shape({
            id: Yup.number().nullable().required('Campo Obrigatório'),
            nome: Yup.string().nullable(),
            sigla: Yup.string().nullable(),
          })
          .nullable(),
      }).nullable(),
    }).nullable(),
    contatos: Yup.array()
      .optional()
      .of(
        Yup.object().shape({
          forma_contato: Yup.string()
            .equals(['TELEFONE', 'EMAIL'])
            .required('Campo Obrigatório'),
          descricao: Yup.string().required('Campo Obrigatório'),
          isWhatsapp: Yup.boolean(),
          observacao: Yup.string().nullable(),
          is_favorito: Yup.boolean().default(false),
        })
      ),
    contato_preferencial: Yup.string().nullable(),
    // estabelecimento_saude: Yup.object({}).required('Campo Obrigatório'),
    vinculo: Yup.object({
      profissionalNome:
        hasVinculo && canUpdateVinculo
          ? Yup.string().required('Campo obrigatório')
          : Yup.string().nullable(),
      cbo:
        hasVinculo && canUpdateVinculo
          ? Yup.string().required('Campo obrigatório')
          : Yup.string().nullable(),
      tipo:
        hasVinculo && canUpdateVinculo
          ? Yup.string().required('Campo obrigatório')
          : Yup.string().nullable(),
      equipeNome:
        hasVinculo && canUpdateVinculo
          ? Yup.string().required('Campo obrigatório').nullable()
          : Yup.string().nullable(),
      estabelecimento_saude:
        hasVinculo && canUpdateVinculo && !disableInputsVinculo
          ? Yup.object({}).required('Campo obrigatório').nullable()
          : Yup.object({}).nullable(),
      // uf:
      //   hasVinculo && canUpdateVinculo
      //     ? Yup.string().required('Campo obrigatório')
      //     : Yup.string().nullable(),
      // municipio:
      //   hasVinculo && canUpdateVinculo
      //     ? Yup.string().required('Campo obrigatório')
      //     : Yup.string().nullable(),
      profissionalCns:
        hasVinculo && canUpdateVinculo
          ? Yup.string().required('Campo obrigatório').nullable()
          : Yup.string().nullable(),
      estabelecimentoSaudeId: Yup.number(),
      equipeIne:
        hasVinculo && canUpdateVinculo
          ? Yup.number().required('Campo obrigatório').nullable()
          : Yup.string().nullable(),
      area:
        hasVinculo && canUpdateVinculo
          ? Yup.string().required('Campo obrigatório').nullable()
          : Yup.string().nullable(),
      micro_area:
        hasVinculo && canUpdateVinculo
          ? Yup.string().required('Campo obrigatório').nullable()
          : Yup.string().nullable(),

      // pessoaId: Yup.number(),
      // profissionalId: Yup.number(),
    }),
  });

  return schema;
};
