/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import ModalEstado from './Modals/ModalEstado';
import ModalEstabelecimento from './Modals/ModalEstabelecimento';
import ModalMunicipio from './Modals/ModalMunicipio';
import ModalEquipe from './Modals/ModalEquipe';
import ModalAcs from './Modals/ModalAcs';
import useActions from './hooks/useActions';
import useStatesFields from './hooks/useStatesFields';
import ModalLaboratorio from './Modals/ModalLaboratorio';

const MultipleFilters = ({
  mdEstado,
  mdMunicipio,
  mdEstabelecimento,
  mdEquipe,
  fieldEquipe,
  fieldAcs,
  fieldResultadoCitologia,
  handleSetFilter,

  acsSelected,
  arrayAcs,
  searchAcs,
  allAcsOptions,
  setAllAcsOptions,
  anchorElAcs,
  disableAcsInput,
  disableEquipeModal = false,
  setDisableAcsInput,
  setAnchorElAcs,
  setArrayAcs,
  setSearchAcs,
  setAcsSelected,

  anchorElEstados,
  searchEstado,
  estadosOptions,
  allEstadosOptions,
  estadosSelected,
  setAnchorElEstados,
  setSearchEstado,
  setEstadosOptions,
  setAllEstadosOptions,
  setEstadosSelected,
  disableInputEstado,

  allMunicipiosOptions,
  searchMunicipio,
  anchorElMunicipios,
  municipiosSelected,
  optionsMunicipios,
  disableMunicipioInput,
  setAllMunicipiosOptions,
  setSearchMunicipio,
  setAnchorElMunicipios,
  setMunicipiosSelected,
  setOptionsMunicipios,
  setDisabledMunicipioInput,

  optionsEstabelecimentos,
  allEstabelecimentosOptions,
  searchEstabelecimentos,
  anchorElEstabelecimentos,
  estabelecimentosSelected,
  disableEstabelecimentoInput,
  setOptionsEstabelecimentos,
  setAllEstabelecimentosOptions,
  setSearchEstabelecimentos,
  setAnchorEstabelecimentos,
  setEstabelecimentosSelected,
  setDisabledEstabelecimentoInput,

  allEquipesOptions,
  searchEquipes,
  anchorElEquipe,
  arrayEquipes,
  equipesSelected,
  disableEquipeInput,
  setAllEquipesOptions,
  setSearchEquipes,
  setAnchorElEquipe,
  setArrayEquipes,
  setEquipesSelected,
  setDisableEquipeInput,

  errorsFields,
  setErrorsFields,
  laboratorioField,
  setDisableOtherInputs,
  page,
  clearValuesInputs,
  routeGetEstabelecimentos,
  setOptionsLaboratorios,
  setAllLaboratoriosOptions,
  disableRequiredEstabelecimento,
  setOptionsServicos,
  setAllServicosOptions,
}: Partial<any>) => {
  const handleSetFiltersInMultiplesInputs = ({
    value,
    fieldName,
    arrayValues,
  }) => {
    const convertedFieldNames = {
      estado: 'Estados',
      municipio: 'Municípios',
      estabelecimento_saude: 'Estabelecimento de Saúde',
      equipe: 'Ine da Equipe',
      acs: 'Agente Comunitário de Saúde',
    };
    if (arrayValues.length > 1) {
      handleSetFilter(
        `${convertedFieldNames[fieldName]}: Vários`,
        fieldName,
        'linha 100 MultipleFilters'
      );
    } else {
      handleSetFilter(
        `${convertedFieldNames[fieldName]}: ${value}`,
        fieldName,
        'linha 105 MultipleFilters'
      );
    }
  };

  const {
    actionsEstadoSelected,
    actionsMunicipioSelected,
    actionsEstabelecimentoSelected,
    actionsEquipeSelected,
    actionsAcsSelected,
  } = useActions();

  const {
    loadingMunicipios,
    loadingEstabelecimentos,
    loadingEquipes,
    loadingAcs,
    loadingEstados,
    loadingLaboratorio,
    setLoadingMunicipios,
    setLoadingEstabelecimentos,
    setLoadingEquipes,
    setLoadingAcs,
    setLoadingEstados,
    setLoadingLaboratorio,
  } = useStatesFields();

  useEffect(() => {
    actionsEstadoSelected({
      estadosSelected,
      setOptionsMunicipios,
      setAllMunicipiosOptions,
      handleSetFiltersInMultiplesInputs,
      setDisabledMunicipioInput,
      handleSetFilter,
      setEstabelecimentosSelected,
      setMunicipiosSelected,
      setEquipesSelected,
      setDisabledEstabelecimentoInput,
      setDisableEquipeInput,
      setLoadingMunicipios,
      errorsFields,
      setErrorsFields,
    });
  }, [estadosSelected]);

  useEffect(() => {
    actionsMunicipioSelected({
      municipiosSelected,
      handleSetFiltersInMultiplesInputs,
      setDisabledEstabelecimentoInput,
      setDisableEquipeInput,
      handleSetFilter,
      estadosSelected,
      setOptionsEstabelecimentos,
      setAllEstabelecimentosOptions,
      setEstabelecimentosSelected,
      setEquipesSelected,
      optionsMunicipios,
      setLoadingEstabelecimentos,
      errorsFields,
      setErrorsFields,
      laboratorioField,
      setDisableOtherInputs,
      clearValuesInputs,
      routeGetEstabelecimentos,
      setOptionsLaboratorios,
      setAllLaboratoriosOptions,
      setOptionsServicos,
      setAllServicosOptions,
      setLoadingLaboratorio,
    });
  }, [municipiosSelected, optionsMunicipios]);

  useEffect(() => {
    actionsEstabelecimentoSelected({
      optionsEstabelecimentos,
      estabelecimentosSelected,
      handleSetFiltersInMultiplesInputs,
      handleSetFilter,
      setDisableEquipeInput,
      setArrayEquipes,
      setAllEquipesOptions,
      setEquipesSelected,
      setAllAcsOptions,
      setArrayAcs,
      setDisableAcsInput,
      fieldEquipe,
      setLoadingEquipes,
      setLoadingAcs,
      setAcsSelected,
      errorsFields,
      setErrorsFields,
    });
  }, [estabelecimentosSelected]);

  useEffect(() => {
    actionsEquipeSelected({
      equipesSelected,
      handleSetFiltersInMultiplesInputs,
      arrayEquipes,
      handleSetFilter,
    });
  }, [equipesSelected]);

  useEffect(() => {
    actionsAcsSelected({
      acsSelected,
      handleSetFiltersInMultiplesInputs,
      arrayAcs,
      handleSetFilter,
    });
  }, [acsSelected]);

  return (
    <>
      <Grid item xs={12} md={mdEstado}>
        <ModalEstado
          anchorElEstados={anchorElEstados}
          searchEstado={searchEstado}
          estadosOptions={estadosOptions}
          allEstadosOptions={allEstadosOptions}
          estadosSelected={estadosSelected}
          setAnchorElEstados={setAnchorElEstados}
          setSearchEstado={setSearchEstado}
          setEstadosOptions={setEstadosOptions}
          setAllEstadosOptions={setAllEstadosOptions}
          setEstadosSelected={setEstadosSelected}
          disableInputEstado={disableInputEstado}
          errors={errorsFields}
          loadingEstados={loadingEstados}
          setLoadingEstados={setLoadingEstados}
        />
      </Grid>
      <Grid item xs={12} md={mdMunicipio}>
        <ModalMunicipio
          allMunicipiosOptions={allMunicipiosOptions}
          searchMunicipio={searchMunicipio}
          anchorElMunicipios={anchorElMunicipios}
          municipiosSelected={municipiosSelected}
          optionsMunicipios={optionsMunicipios}
          disableMunicipioInput={disableMunicipioInput}
          setAllMunicipiosOptions={setAllMunicipiosOptions}
          setSearchMunicipio={setSearchMunicipio}
          setAnchorElMunicipios={setAnchorElMunicipios}
          setMunicipiosSelected={setMunicipiosSelected}
          setOptionsMunicipios={setOptionsMunicipios}
          loadingMunicipios={loadingMunicipios}
          errors={errorsFields}
        />
      </Grid>
      <>
        {laboratorioField ? (
          <>
            <Grid item xs={12} md={mdEstabelecimento}>
              <ModalLaboratorio
                optionsEstabelecimentos={optionsEstabelecimentos}
                allEstabelecimentosOptions={allEstabelecimentosOptions}
                searchEstabelecimentos={searchEstabelecimentos}
                anchorElEstabelecimentos={anchorElEstabelecimentos}
                estabelecimentosSelected={estabelecimentosSelected}
                disableEstabelecimentoInput={disableEstabelecimentoInput}
                setOptionsEstabelecimentos={setOptionsEstabelecimentos}
                setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
                setSearchEstabelecimentos={setSearchEstabelecimentos}
                setAnchorEstabelecimentos={setAnchorEstabelecimentos}
                setEstabelecimentosSelected={setEstabelecimentosSelected}
                loadingLaboratorio={loadingLaboratorio}
                errors={errorsFields}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={mdEstabelecimento}>
              <ModalEstabelecimento
                optionsEstabelecimentos={optionsEstabelecimentos}
                allEstabelecimentosOptions={allEstabelecimentosOptions}
                searchEstabelecimentos={searchEstabelecimentos}
                anchorElEstabelecimentos={anchorElEstabelecimentos}
                estabelecimentosSelected={estabelecimentosSelected}
                disableEstabelecimentoInput={disableEstabelecimentoInput}
                setOptionsEstabelecimentos={setOptionsEstabelecimentos}
                setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
                setSearchEstabelecimentos={setSearchEstabelecimentos}
                setAnchorEstabelecimentos={setAnchorEstabelecimentos}
                setEstabelecimentosSelected={setEstabelecimentosSelected}
                loadingEstabelecimentos={loadingEstabelecimentos}
                errors={errorsFields}
                disableRequiredEstabelecimento={disableRequiredEstabelecimento}
              />
            </Grid>
          </>
        )}
      </>

      {fieldEquipe && !disableEquipeModal && (
        <Grid item xs={12} md={mdEquipe}>
          {fieldEquipe && (
            <ModalEquipe
              allEquipesOptions={allEquipesOptions}
              searchEquipes={searchEquipes}
              anchorElEquipe={anchorElEquipe}
              arrayEquipes={arrayEquipes}
              equipesSelected={equipesSelected}
              disableEquipeInput={disableEquipeInput}
              setAllEquipesOptions={setAllEquipesOptions}
              setSearchEquipes={setSearchEquipes}
              setAnchorElEquipe={setAnchorElEquipe}
              setArrayEquipes={setArrayEquipes}
              setEquipesSelected={setEquipesSelected}
              loadingEquipes={loadingEquipes}
            />
          )}
          {fieldAcs && (
            <ModalAcs
              acsSelected={acsSelected}
              arrayAcs={arrayAcs}
              searchAcs={searchAcs}
              allAcsOptions={allAcsOptions}
              anchorElAcs={anchorElAcs}
              disableAcsInput={disableAcsInput}
              setAnchorElAcs={setAnchorElAcs}
              setArrayAcs={setArrayAcs}
              setSearchAcs={setSearchAcs}
              setAcsSelected={setAcsSelected}
              loadingAcs={loadingAcs}
            />
          )}

          {fieldResultadoCitologia && <></>}
        </Grid>
      )}
    </>
  );
};

export default MultipleFilters;
