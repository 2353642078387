import { useContext } from 'react';
import { LoadingContext } from '../../../../../context/LoadingContext';
import { getReportsConsolidado } from '../../../../../lib/Reports/getReportsConsolidado';

const useRequestFilter = ({ setTableData }) => {
  const { setLoading } = useContext(LoadingContext);
  const loadReportsConsolidado = async (payload) => {
    const response = await getReportsConsolidado({
      payload,
    });

    setTableData(response);
    setLoading(false);
  };

  return {
    loadReportsConsolidado,
  };
};

export default useRequestFilter;
